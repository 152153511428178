import { Breadcrumb, Button } from 'components'
import { DateTime } from 'luxon'

import { getProfile } from 'api/profile'

export const Dashboard = () => {
	let email
	let profile
	getProfile().then((p) => {
		if (p) {
			email = p.email
			profile = p
		}
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Dashboard',
						},
					],
				}),
				m('.my-4.px-4', [
					m('div.shadow.h-full.border-b.border-gray-200.bg-white.sm:rounded-lg', [
						m('.p-4', 'Welcome to BIFA 2024'),
						m('.p-4', `View entries and forms for this year's awards:`),

						m('.p-4.flex.gap-x-3', [m(Button, { href: '/entries' }, 'Entries')]),
					]),
				]),
			]
		},
	}
}
