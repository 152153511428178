import {
	Breadcrumb,
	Svg,
	Card,
	Table,
	ExternalLink,
	Button,
	TextArea,
	Checkbox,
	RadioGroup,
	LoadingFullScreen,
	Dialog2,
} from 'components'
import { DateTime } from 'luxon'
import groupBy from 'lodash.groupby'

import { getFilm, downloadEntryAttachment, setEntrantConfirm, setEntrantLonglistConfirm } from 'api/films'

import infoIcon from 'assets/icons/outline/information-circle.svg'
import bookmarkIcon from 'assets/icons/outline/bookmark.svg'
import downloadIcon from 'assets/icons/outline/download.svg'

import { notification } from 'utils/notifications'

export const Film = ({ attrs: { id, tab, tabName, subteam, categoryId } }) => {
	const root = '/film'
	let film
	let statementOpen = flyd.stream(false)
	let selectedStatement
	const dtFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }

	let entries
	const loadData = () => {
		getFilm(id)
			.then((res) => {
				entries = groupBy(
					res.entries.filter((e) => {
						return !e.subgroupIsHidden && e.votingCategoryTypeId !== 6 && e.entryStatusId !== 4
					}),
					(a) => {
						return a.entryId
					}
				)
				film = res
			})
			.catch(() => {
				m.route.set('/entries')
			})
	}
	loadData()

	let confirmSaving = false
	const confirmFilm = () => {
		confirmSaving = true
		setEntrantConfirm(id)
			.then((res) => {
				notification.success({ title: 'Confirmation saved' })
				confirmSaving = false
				m.route.set('/entries')
			})
			.catch((err) => {
				if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
					Sentry.captureException(err)
				}
				notification.error({ title: 'Confirmation failed' })
				confirmSaving = false
			})
	}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entries',
							href: `/entries/`,
						},
						{
							title: film ? film.title : '...',
						},
					],
				}),
				m('.flex.flex-wrap.relative.h-screen.pb-24.overflow-x-hidden.content-start', [
					m('.p-4.w-full', [
						m('.grid.grid-cols-3.lg:grid-cols-5.gap-6', [
							m('.col-span-2.lg:col-span-3', [
								film
									? m('.flex.flex-col', [
											m('.shadow.sm:rounded-md.bg-white.p-4.mb-6', [
												film.posterUrl &&
													m('img.float-right.pl-6', { style: 'width:300px', src: `${film.posterUrl}?w=600&fm=jpg` }),
												m('.text-2xl.mb-2', film.title),

												m('.text-base.mb-6', m.trust(film.description)),
												m('hr'),
												m('.font-bold.text-sm.mt-3', 'Running Time (mins)'),
												m('.text-base.mb-2', film.runningTime),
												m('hr'),
												m('.font-bold.text-sm.mt-3', 'Screening'),
												m('.text-base.mb-2', film.screeningInfo),
												film.screenerDates && m('.text-base.mb-2', film.screenerDates),
												film.screenerRestrictions && m('.text-base.mb-2', film.screenerRestrictions),
												film.screeningLinkPassword &&
													m('.flex.gap-3.mt-2.text-sm', [
														m('span.font-bold', 'Password:'),
														m('span', film.screeningLinkPassword),
													]),
												m('.clear-both'),
											]),
											m('.shadow.sm:rounded-md.bg-white.p-4.col-span-2', [
												m('.text-xl.mb-2', 'Entries'),
												m('table.table.table-fixed.w-full', [
													m('thead', [
														m('tr.text-left', [
															m('th.bg-gray-50.p-1.w-2/5', 'Category'),
															m('th.bg-gray-50.p-1.w-2/5', 'People'),
														]),
													]),
													m('tbody', [
														entries &&
															Object.keys(entries).map((x) => {
																let entry = entries[x][0]
																return m('tr', [
																	m('td.p-1.border-b.border-gray-200', entry.votingCategory),
																	m(
																		'td.p-1.border-b.border-gray-200',
																		entries[x].length
																			? m('ul.list-unstyled', [
																					entries[x].map((z) => {
																						return m('li.list-item.text-sm', [
																							//m(icon, { iconName: 'user mr-1' }),
																							z.person,
																							z.character ? ` (${z.character})` : z.role ? ` (${z.role})` : null,
																						])
																					}),
																					entry.attachments.length
																						? entry.attachments.map((file) => {
																								return m('li.list-item', [
																									m(
																										Button,
																										{
																											type: 'button',
																											size: 'xs',
																											onclick() {
																												downloadEntryAttachment(file.id, file.name)
																											},
																										},
																										[m(Svg, { classes: ['w-4', 'h-4'] }, downloadIcon), file.name]
																									),
																								])
																						  })
																						: null,
																					entry.statement
																						? m(
																								Button,
																								{
																									type: 'button',
																									size: 'xs',
																									onclick() {
																										selectedStatement = entry.statement
																										statementOpen(true)
																									},
																								},
																								'Statement'
																						  )
																						: null,
																			  ])
																			: null
																	),
																])
															}),
													]),
												]),
											]),
									  ])
									: m(LoadingFullScreen),
							]),
							m(
								'.lg:col-span-2.relative',
								m(
									Card,
									{
										classes: ['relative', 'sticky', 'top-0'],
										buttons:
											film && !film.isSignedOff
												? [
														m(
															Button,
															{
																variant: 'positive',
																type: 'button',
																onclick() {
																	confirmFilm()
																},
															},
															'Confirm Listing'
														),
												  ]
												: null,
										heading: { title: 'Confirmation' },
									},
									[
										film
											? m('div', [
													!film.isSignedOff &&
														m('ul.list-disc.text-sm.ml-4', [
															m('li', 'Please thoroughly check category listings and spellings.'),
															m(
																'li',
																'All entries in the following categories should have supporting statements: Best Casting and Best Effects (including a breakdown reel).'
															),
															m(
																'li',
																'All Screenplay and Debut Screenwriter entries should also have a screenplay sample.'
															),
															m(
																'li',
																'All Music Supervision and Original Music entries should have a music cue sheet.'
															),
															m(
																'li',
																'Any missing materials and missing online screeners should be emailed to entry@bifa.film by Monday 16th September'
															),
															m(
																'li',
																'To make amendments to your entry, email entry@bifa.film by Monday 16th September at 6pm.'
															),
														]),
													!film.isSignedOff
														? m(
																'p.text-sm.font-bold.mt-2',
																'Please mark the box below to confirm that you have checked your entry (deadline Monday 16th September at 6pm).'
														  )
														: m(
																'p.text-sm.font-bold.mt-2',
																`Film signed off: ${DateTime.fromISO(film.signedOffDate).toLocaleString(dtFormat)}`
														  ),
											  ])
											: m(LoadingFullScreen),
									]
								)
							),
						]),
					]),
				]),
				statementOpen() &&
					m(StatementDialog, {
						open: statementOpen,
						statement: selectedStatement,
					}),
			]
		},
	}
}

const StatementDialog = ({ attrs: { loading } }) => {
	loading = false

	return {
		view({ attrs: { statement, open } }) {
			return m(
				Dialog2,
				{
					open,
					heading: 'Statement',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[m('.flex', statement)]
			)
		},
	}
}
