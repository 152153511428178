export const File = () => {
	return {
		view({ attrs: { size = 'md', classes = [], oninput = () => {}, errorMessage }, children, loading }) {
			return [
				m(
					'label',
					{
						class: [
							'block text-center cursor-pointer text-sm',
							'rounded relative py-1 px-4 text-sm bg-blue-500 hover:bg-blue-400 text-white border-blue-700 hover:border-blue-500',

							...classes,
						].join(' '),
					},
					[
						m('span', loading ? 'Uploading...' : children),
						m('input', {
							class: 'hidden',
							type: 'file',
							disabled: loading ? true : null,
							onchange(e) {
								oninput(e.target.files[0])
							},
						}),
					]
				),
				errorMessage && m('.text-red-600.text-sm', errorMessage),
			]
		},
	}
}
