export const getError = (errors, path) => {
  let field
  if (Array.isArray(path)) {
    field = errors.find(err => err.key.every((val, k) => path[k] === val))
  } else {
    field = errors.find(err => err.key[0] === path)
  }
  if (field) return field.message
}

export const validate = (schema, data) => {
  const validation = schema.validate(data, { abortEarly: false })
  if (!validation.error) return
  return validation.error.details.map(detail => {
    return {
      key: detail.path,
      message: detail.message
    }
  })
}

export const displayErrors = errs => {
  return errs &&
    m('div', [
      errs.map(err => {
        return m('p.text-xs.text-red-600.mb-2', err)
      })
    ])
}
