import { RadioGroup } from 'components'

export const QuestionYesNo = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	return {
		view() {
			return m(RadioGroup, {
				label: question.name,
				horizontal: true,
				labelBig: true,
				name: `rg_${question.id}`,
				options: [
					{ label: 'Yes', value: 'Yes' },
					{ label: 'No', value: 'No' },
				],
				required: question.isRequired,
				value: onGetFormAnswer(question.id),
				onchange(value) {
					onSetFormAnswer(question, value)
				},
			})
		},
	}
}
