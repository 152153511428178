export const Radio = () => {
  return {
    view ({ attrs: { label, value, desc, checked, required, name, onchange } }) {
      return m('.flex.flex-col.mb-3', [
        m('.flex.items-center', [
          m('input.focus:ring-indigo-500.h-4.w-4.text-indigo-600.border-gray-300', {
            type: 'radio',
            value,
            name,
            checked,
            required: required ? 'required' : null,
            onclick (e) {
              onchange(value)
            }
          }),
          m('label.ml-4', {
            onclick (e) {
              onchange(value)
            }
          }, [
            m('span.block.text-sm.font-medium.text-gray-700', label)
          ])
        ]),
        desc && m('p.text-gray-500.text-sm.ml-8', desc)

      ])
    }
  }
}
