import { Checkbox } from 'components'

export const QuestionCheckbox = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	return {
		view() {
			return m(Checkbox, {
				label: question.name,
				name: `cb_${question.id}`,
				required: question.isRequired,
				checked: onGetFormAnswer(question.id),
				onchange() {
					onSetFormAnswer(question, !onGetFormAnswer(question.id))
				},
			})
		},
	}
}
