import { Card, Heading, Svg, Input, Button, Tooltip } from 'components'
import { validate, getError, displayErrors } from 'validators/utils'
import { getFormBySlug, submitForm } from 'api/forms'
import {
	QuestionText,
	QuestionTextArea,
	QuestionSelect,
	QuestionYesNo,
	QuestionUpload,
	QuestionCheckbox,
} from './QuestionTypes'
import logo from 'assets/logo.svg'
import alertIcon from 'assets/icons/outline/question-mark-circle.svg'
import { notification } from 'utils/notifications'

const formData = {}

export const Form = ({ attrs: { slug } }) => {
	let errors = []
	let serverErrors
	let loading
	let form
	let answers

	getFormBySlug(slug)
		.then((res) => {
			answers = res.questions.map((q) => {
				return { questionId: q.id, value: null }
			})
			form = res
		})
		.catch((err) => {
			if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
				Sentry.captureException(err)
			}
			serverErrors = ['The form cannot be found']
		})

	const setFormAnswer = (question, val) => {
		debugger
		let valStr = JSON.stringify(val)
		answers.find((a) => {
			return a.questionId === question.id
		}).value = typeof val === 'boolean' ? val : valStr
		if (typeof val === 'File') {
			answers.find((a) => {
				return a.questionId === question.id
			}).valueFiles = val
		}
		m.redraw()
	}
	const setFormFileAnswer = (question, val) => {
		answers.find((a) => {
			return a.questionId === question.id
		}).value = JSON.stringify(val.name)
		answers.find((a) => {
			return a.questionId === question.id
		}).valueFiles = val
		m.redraw()
	}

	const getFormAnswer = (questionId) => {
		const val = answers.find((a) => {
			return a.questionId === questionId
		}).value
		return typeof val === 'boolean'
			? val
			: val
			? JSON.parse(
					answers.find((a) => {
						return a.questionId === questionId
					}).value
			  )
			: ''
	}

	const isFormAnswerMatched = (question) => {
		const answer = getFormAnswer(question.visibleQuestionId)
		let response = false
		if (answer) {
			const matchValue = question.visibleQuestionValue

			if ((Array.isArray(answer) && answer.includes(matchValue)) || answer === matchValue) {
				response = true
			} else if (matchValue.indexOf('||')) {
				let matchValueArr = matchValue.split('||')
				matchValueArr.forEach((element) => {
					if ((Array.isArray(answer) && answer.includes(element)) || answer === element) {
						response = true
					}
				})
			}
		}
		return response
	}

	const onFormSubmit = () => {
		loading = true
		submitForm(form.id, {
			formId: form.id,
			answers,
		})
			.then((res) => {
				notification.success({ title: 'Form Submitted' })
				loading = false
				m.route.set('/thank-you')
			})
			.catch((err) => {
				if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
					Sentry.captureException(err)
				}
				notification.error({ title: 'Form Error, please contact us for more information' })
				loading = false
			})
	}

	return {
		view() {
			return m('.flex.flex-col.justify-center.items-center.bg-black.pt-10', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-3xl', 'my-3'],
					},
					[
						serverErrors && displayErrors(serverErrors),
						form &&
							m('.p-6', [
								m(Heading, form.name),
								form.description && m('.text-base.space-y-2.mt-2', m.trust(form.description)),
								m(
									'form',
									{
										onsubmit(e) {
											e.preventDefault()
											onFormSubmit()
										},
									},
									[
										m('.flex.flex-col.mt-6.space-y-6', [
											form.questions.map((question) => {
												return question.visibleQuestionId === null || isFormAnswerMatched(question)
													? m('.flex', [
															m('.grow', [
																question.questionTypeId === 1 || // Text
																question.questionTypeId === 6 || // Email
																question.questionTypeId === 9 // Date
																	? m(QuestionText, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormAnswer,
																	  })
																	: question.questionTypeId === 2
																	? m(QuestionTextArea, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormAnswer,
																	  })
																	: question.questionTypeId === 3 || question.questionTypeId === 5
																	? m(QuestionSelect, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormAnswer,
																	  })
																	: question.questionTypeId === 4
																	? m(QuestionYesNo, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormAnswer,
																	  })
																	: question.questionTypeId === 7 // Checkbox
																	? m(QuestionCheckbox, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormAnswer,
																	  })
																	: question.questionTypeId === 8
																	? m('h4.text-lg.font-bold', question.name)
																	: question.questionTypeId === 10
																	? m(QuestionUpload, {
																			question,
																			onGetFormAnswer: getFormAnswer,
																			onSetFormAnswer: setFormFileAnswer,
																	  })
																	: question.questionTypeId === 11 // Section Text
																	? m('div', question.info && m('.text-sm', m.trust(question.info)))
																	: m('p', question.name),
															]),
															m(
																'.w-24',
																question.help &&
																	m(Tooltip, {
																		tipContent: question.help,
																		icon: alertIcon,
																		colorClass: 'bg-teal-600 text-white',
																	})
															),
													  ])
													: null
											}),
										]),
										m('.flex.justify-end.items-center.mt-6', [
											m(
												Button,
												{
													variant: 'positive',
													loading: loading,
												},
												'Submit Answers'
											),
										]),
									]
								),
							]),
					]
				),
			])
		},
	}
}
