import { Svg } from 'components/Svg'
import loadingIcon from 'assets/loading.svg'

const styles = {
  initial: ['rounded', 'relative'],
  size: {
    md: ['py-2', 'px-4'],
    sm: ['py-1', 'px-2', 'text-sm'],
    xs: ['py-1', 'px-2', 'text-xs'],
    'sm-square': ['py-1', 'px-1', 'text-sm']
  },
  variant: {
    plain: ['bg-gray-200', 'hover:bg-gray-300', 'text-black', 'font-bold'],
    positive: ['bg-green-500', 'hover:bg-green-400', 'text-white', 'border-green-700', 'hover:border-green-500'],
    info: ['bg-blue-500', 'hover:bg-blue-400', 'text-white', 'border-blue-700', 'hover:border-blue-500'],
    danger: ['bg-red-500', 'hover:bg-red-400', 'text-white', 'border-red-700', 'hover:border-red-500'],
    warning: ['bg-orange-500', 'hover:bg-orange-400', 'text-white', 'border-orange-700', 'hover:border-orange-500'],
    circle: ['rounded-full', 'h-12', 'w-12', 'shadow-lg', 'bg-blue-500', 'text-white', 'flex', 'justify-center', 'items-center'],
    delete: ['bg-gray-200', 'hover:bg-red-300', 'text-red-600', 'font-bold']
  }
}

const loadingSpinner = () => {
  return m(Svg, {
    classes: ['w-6', 'text-white', 'rotate', 'm-auto']
  }, loadingIcon)
}

export const Button = () => {
  return {
    view ({
      children,
      attrs: {
        href,
        size = 'md',
        variant = 'info',
        block = false,
        onclick = () => {},
        classes = [],
        loading,
        icon,
        type = 'submit'
      }
    }) {
      const tag = href ? m.route.Link : 'button'
      return m(tag, {
        href,
        onclick,
        type,
        disabled: loading ? 'true' : null,
        class: [
          block ? 'w-full' : '',
          loading ? 'pointer-events-none' : '',
          ...styles.initial,
          ...styles.size[size],
          ...styles.variant[variant],
          ...classes
        ].join(' ')
      }, [
        m('span.flex.gap-2.items-center.justify-center', {
          class: loading ? 'opacity-0' : ''
        }, [
          icon && m(Svg, { classes: [size === 'xs' ? 'h-4' : size === 'sm' ? 'h-5' : 'h-6'] }, icon),
          children
        ]),
        loading &&
        m('.absolute-center', loadingSpinner())
      ])
    }
  }
}
