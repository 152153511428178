export const Link = () => {
  return {
    view ({ children, attrs: { href, disabled, classes = [], options = {}, stopPropagation } }) {
      return m(m.route.Link, {
        href,
        disabled: false,
        options,
        class: ['hover:underline', ...classes].join(' '),
        onclick (e) {
          if (stopPropagation) { e.stopPropagation() }
        }
      }, children)
    }
  }
}
