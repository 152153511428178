import { Input, Button } from 'components'

export const QuestionCustomMultiple = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	let customs = onGetFormAnswer(question.id) ? onGetFormAnswer(question.id) : [['', '', '']]
	return {
		view() {
			return m('.flex.flex-col', [
				m('label.block.text-sm.font-bold.text-gray-700', question.name),
				customs.map((custom, cix) => {
					return m('.flex.gap-x-1', [
						question.options &&
							JSON.parse(question.options).map((option, ix) => {
								return m(
									'.grow',
									m(Input, {
										label: option,
										name: `input_${question.id}_${cix}_${ix}`,
										type: 'text',
										required: question.isRequired,
										value: custom ? custom[ix] : '',
										oninput(val) {
											custom[ix] = val
											onSetFormAnswer(question, customs)
										},
									})
								)
							}),
					])
				}),
				m(
					'.w-32',
					question.maxValue !== customs.length &&
						m(
							Button,
							{
								type: 'button',
								size: 'xs',
								variant: 'positive',
								onclick() {
									customs.push(Array.from(''.repeat(JSON.parse(question.options).length)))
									m.redraw()
								},
							},
							'Add new row'
						)
				),
			])
		},
	}
}
