import { Card, Heading, Svg, Input, Button, Breadcrumb, Tooltip, LoadingFullScreen } from 'components'
import { validate, getError, displayErrors } from 'validators/utils'
import { setEntryFormAnswersPaid } from 'api/forms'

import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'

const formData = {}

export const EntryFormPaid = ({ attrs: { id } }) => {
	let serverErrors
	let answers
	const dtFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }

	setEntryFormAnswersPaid(id).then((fa) => {
		answers = fa
	})
	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entries',
							href: '/entries',
						},
						{
							title: answers ? answers.form.name : '...',
						},
						{
							title: 'Paid',
						},
					],
				}),
				serverErrors && displayErrors(serverErrors),
				answers
					? m('.grid.sm:grid-cols-5', [
							m(
								'.sm:col-span-4.flex.flex-col.justify-center.sm:flex-row.px-2.sm:px-0.relative.order-2.sm:order-1.mt-6',
								[
									m(
										Card,
										{
											//classes: ['sm:w-3/4', 'my-1', 'mx-2'],
										},
										[
											m('.p-6', [
												m(Heading, answers.form.name),
												m('div', {}, [m('.flex.flex-col.mt-6', [m('p', 'Thank you for submitting your film')])]),
											]),
										]
									),
								]
							),
							m('.sticky.top-0.z-10.sm:relative.w-full.p-4.order-1.sm:order-2.bg-gray-50.m-2', [
								answers &&
									m(
										'.sticky.flex.flex-col',
										{
											style: 'top:1rem',
										},
										[
											m('dl.mt-1.flex.flex-grow.flex-col.justify-between', [
												m('p.text-center', answers.filmTitle),
												m('dt.sr-only', 'Status'),
												m(
													'dd.mt-1.text-center',
													m(
														'span.inline-flex.items-center.rounded-full.px-2.py-1.text-xs.font-medium.ring-1.ring-inset',
														{
															class:
																answers.submissionStatusId == 1
																	? 'bg-orange-100 text-orange-700 ring-orange-300'
																	: 'bg-green-100 text-green-700 ring-green-600',
														},
														answers.submissionStatusName
													)
												),
												m('dl.mt-1.flex.gap-x-2.justify-center', [
													m('dt.text-xs.text-gray-500', 'Created:'),
													m('dd.text-xs.text-gray-500', DateTime.fromISO(answers.createdDate).toLocaleString(dtFormat)),
												]),
											]),
										]
									),
							]),
					  ])
					: m(LoadingFullScreen),
			]
		},
	}
}
