import { TextArea } from 'components'

export const QuestionTextArea = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	return {
		view() {
			return m(TextArea, {
				value: onGetFormAnswer(question.id),
				label: question.name,
				name: `sel_q_${question.id}`,
				rows: 4,
				required: question.isRequired,
				oninput: (content) => {
					onSetFormAnswer(question, content)
				},
			})
		},
	}
}
