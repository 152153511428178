import { UserAvatar } from 'components/Avatar'
import { LogoutDialog } from '../LogoutDialog'

import { profile } from 'store/profile'

export const NavbarUser = () => {
	const open = flyd.stream(false)
	const showLogoutDialog = flyd.stream(false)
	const bodyClickFunction = (e) => {
		open(false)
	}
	const toggleBodyClick = () => {
		if (open()) {
			document.body.addEventListener('click', bodyClickFunction)
		} else {
			document.body.removeEventListener('click', bodyClickFunction)
		}
	}
	return {
		view() {
			return m('div', [
				profile()
					? m('.mr-4', [
							m(
								'button.max-w-xs.flex.items-center.text-sm.rounded-full.text-white.focus:outline-none.focus:shadow-solid',
								{
									onclick(e) {
										e.stopPropagation()
										open(true)
										toggleBodyClick()
									},
								},
								[m(UserAvatar, { user: profile() })]
							),
							open() &&
								m('.origin-top-right.absolute.right-0.mt-2.mr-2.w-48.rounded.shadow-lg.z-40', [
									m('.py-1.rounded.bg-white.shadow-xs', [
										m(
											m.route.Link,
											{
												href: '/settings',
												class: [
													'block',
													'px-4',
													'py-2',
													'text-sm',
													'text-gray-700',
													'hover:bg-gray-100',
												].join(' '),
											},
											t('app', 'settings')
										),
										m(
											'button',
											{
												onclick() {
													showLogoutDialog(true)
												},
												class: [
													'block',
													'text-left',
													'w-full',
													'px-4',
													'py-2',
													'text-sm',
													'text-gray-700',
													'hover:bg-gray-100',
												].join(' '),
											},
											t('app', 'logOut')
										),
									]),
								]),
					  ])
					: m(
							'button',
							{
								class: 'block mt-4 lg:inline-block lg:mt-0 text-orange-100 hover:text-white mr-4',
								onclick() {
									showLogoutDialog(true)
								},
							},
							'Settings'
					  ),
				m(LogoutDialog, {
					open: showLogoutDialog,
				}),
			])
		},
	}
}

// const Navbar = () => {
//   return {
//     view ({ attrs: { links } }) {
//       return m('nav', {
//         class: 'flex items-center justify-between flex-wrap bg-orange-500 h-16'
//       }, [
//         m('.w-64', { class: 'flex items-center justify-center flex-shrink-0 text-white mr-6 h-full bg-orange-600 p-2 shadow' },
//           [
//             m('img.w-32.select-none', {
//               src: logo
//             })
//           ]
//         ),
//         m('div', { class: 'block lg:hidden' },
//           m('button', { class: 'flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white' },
//             m('svg', {
//               class: 'fill-current h-3 w-3',
//               viewBox: '0 0 20 20',
//               xmlns: 'http://www.w3.org/2000/svg'
//             },
//             [
//               m('title',
//                 'Menu'
//               ),
//               m('path', { d: 'M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' })
//             ]
//             )
//           )
//         ),
//         m('div', {
//           class: 'w-full block flex-grow lg:flex lg:items-center lg:w-auto'
//         }, [
//           m('div', { class: 'text-sm lg:flex-grow' }, [
//             links.map(l => {
//               return (!l.group || l.group === 'left') && m(NavbarLink, l)
//             })
//           ]),
//           m('div', { class: 'text-sm' }, [
//             m(NavbarUser)
//           ])
//         ])
//       ])
//     }
//   }
// }
