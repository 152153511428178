import { Svg } from 'components/Svg'
import homeIcon from 'assets/icons/solid/home.svg'

export const Breadcrumb = () => {
  return {
    view ({ attrs: { links, buttons } }) {
      return m("nav.bg-white.border-b.border-gray-200.flex.w-full[aria-label='Breadcrumb']",
        m('ol.max-w-screen-xl.w-full.mx-auto.px-4.flex.space-x-4.sm:px-6.lg:px-8',
          [
            m('li.flex',
              m('div.flex.items-center',
                m(m.route.Link, {
                  class: 'text-gray-400 hover:text-gray-500',
                  href: '/'
                },
                [
                  m(Svg, {
                    classes: ['flex-shrink-0', 'h-5', 'w-5']
                  }, homeIcon),
                  m('span.sr-only',
                    'Home'
                  )
                ]
                )
              )
            ),
            links && links.map(l => {
              return l && m('li.flex',
                m('div.flex.items-center',
                  [
                    m("svg.flex-shrink-0.w-6.h-full.text-gray-200[viewBox='0 0 24 44'][preserveAspectRatio='none'][fill='currentColor'][xmlns='http://www.w3.org/2000/svg'][aria-hidden='true']",
                      m("path[d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z']")
                    ),
                    l.href ? m(m.route.Link, {
                      class: 'ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 leading-tight',
                      href: l.href
                    }, l.title) : m('span.ml-4.text-sm.font-medium.text-gray-500 leading-tight',
                      l.title
                    )
                  ]
                )
              )
            })

          ]
        ),
        buttons && m('.flex.mr-3', buttons)
      )
    }
  }
}
