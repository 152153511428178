import { DateTime } from 'luxon'

export const formatCurrency = (value, currency, dp) => {
	// for use of currency as an object. Should replace format money

	if (isNaN(value)) {
		return `${currency && currency.symbol ? currency.symbol : '£'} -`
	}
	return Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: currency && currency.code ? currency.code : 'GBP',
		maximumFractionDigits: isNaN(dp) ? 2 : dp,
	}).format(value)
}

export const formatMoney = (value, currency, dp) => {
	// for use of currency as a symbol
	if (isNaN(value)) {
		return `${currency} -`
	}

	var formatValue = Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: 'GBP',
		maximumFractionDigits: isNaN(dp) ? 2 : dp,
	}).format(value)

	if (currency && currency !== '£') {
		formatValue = formatValue.replace('£', currency)
	}

	return formatValue

	// const floatValue = parseFloat(value)
	// return `${currency} ${dp ? floatValue.toFixed(dp) : Number.isInteger(floatValue) ? floatValue : floatValue.toFixed(2)}`
}

export const nl2br = (str, isXHTML) => {
	if (typeof str === 'undefined' || str === null) {
		return ''
	}
	var breakTag = isXHTML || typeof isXHTML === 'undefined' ? '<br />' : '<br>'
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const formatCommission = (value, dp) => {
	if (isNaN(value)) {
		return '-'
	}

	const pc = value * 100
	return `${dp ? pc.toFixed(dp) : Number.isInteger(pc) ? pc : pc.toFixed(1)} %`
}

export const timeUntilEmailSent = (created, delay) => {
	const seconds = delay - DateTime.now().diff(DateTime.fromISO(created), 'second').seconds
	return seconds < 0
		? '0s'
		: seconds <= 60
		? `Sending in ${seconds.toFixed(0)} s`
		: `Sending in ${(seconds / 60).toFixed(0)} mins`
}

export const trimStrings = (obj) => {
	Object.keys(obj).forEach((k) => {
		obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]
	})
}
