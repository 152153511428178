import { request } from './request'
import { hasStoredToken } from 'store/auth'

export const getUserFilms = () => {
	return request({
		url: 'voting/api/films/my',
		method: 'GET',
	})
}

export const getFilm = (id) => {
	return request({
		url: `voting/api/films/my/${id}`,
		method: 'GET',
	})
}

export const setEntrantConfirm = (id) => {
	return request({
		url: `voting/api/films/${id}/confirm`,
		method: 'POST',
	})
}

export const setEntrantLonglistConfirm = (id) => {
	return request({
		url: `voting/api/films/${id}/confirm-longlisted`,
		method: 'POST',
	})
}

export const downloadEntryAttachment = (entryAttachmentId, name) => {
	return request({
		url: `voting/films/entry-file?entryAttachmentId=${entryAttachmentId}`,
		method: 'GET',
		responseType: 'blob',
		extract(val) {
			return val.response
		},
	})
		.then((res) => {
			const date = new Date()
			var anchor = document.createElement('a')
			anchor.download = name
			anchor.href = window.URL.createObjectURL(res)
			anchor.click()
		})
		.catch((res) => {
			console.log(res)
		})
}
