import { refreshAuthTokenOrWait } from 'store/auth'

export const request = (options) => {
	const apiOptions = {
		...options,
		url: `https://${process.env.API_DOMAIN}/${options.url}`,
	}
	if (!apiOptions.headers) apiOptions.headers = {}
	apiOptions.headers['Client-Version'] = process.env.APP_VERSION
	if (options['content-type']) {
		apiOptions.headers['Content-Type'] = options['content-type']
	}
	if (options.method === 'PATCH') {
		apiOptions.headers['Content-Type'] = 'application/json-patch+json'
	}
	let requestOptions = apiOptions
	return refreshAuthTokenOrWait(apiOptions.url)
		.catch(() => {
			m.route.set('/logout')
		})
		.then((token) => {
			if (token) {
				requestOptions = token.sign(apiOptions)
				return m.request(requestOptions)
			}
		})
}
