import { request } from './request'

export const getVersion = () => {
	return m.request({
		url: `https://${process.env.API_DOMAIN}/version`,
		method: 'GET',
	})
}

export const getEmailMessages = (params) => {
	return request({
		url: 'emailmessages',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const cancelEmailMessage = (id) => {
	return request({
		url: `emailmessages/${id}/cancel`,
		method: 'PUT',
	})
}
export const deleteEmailMessage = (id) => {
	return request({
		url: `emailmessages/${id}`,
		method: 'DELETE',
	})
}

export const getEmailMessage = (id) => {
	return request({
		url: `emailmessages/${id}`,
		method: 'GET',
	})
}
