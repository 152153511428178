const styles = {
  1: ['text-2xl', 'font-bold', 'text-gray-700'],
  2: ['text-xl', 'font-bold', 'text-gray-700'],
  3: ['text-lg', 'font-bold', 'text-gray-600'],
  4: ['text-base'],
  5: ['text-sm', 'font-bold', 'uppercase'],
  delegate: ['text-lg', 'lg:text-2xl', 'text-black']
}

export const Heading = () => {
  return {
    view ({ children, attrs: { level = 1, classes = [] } }) {
      const tag = `h${level === 'delegate' ? 1 : level}`
      return m(tag, {
        class: [
          ...styles[level],
          ...classes
        ].join(' ')
      }, children)
    }
  }
}
