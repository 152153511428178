import { Select } from 'components'

export const QuestionSelect = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	return {
		view() {
			return m('div', [
				m(Select, {
					label: question.name,
					name: `select_${question.id}`,
					search: false,
					subtype: question.questionTypeId === 5 || question.questionTypeId === 17 ? 'multiple' : null,
					required: question.isRequired,
					value: onGetFormAnswer(question.id),
					onchange(val) {
						onSetFormAnswer(question, val)
					},
					strValue: true,
					choices:
						question.options &&
						JSON.parse(question.options).map((option) => {
							return { label: option, value: option }
						}),
				}),
			])
		},
	}
}
