import { clearAuth } from 'store/auth'
import { clearProfile } from 'store/profile'

export const Logout = () => {
  return {
    view () {
      clearAuth()
      clearProfile()
      localStorage.clear()
      m.route.set('/login')
      return m('div')
    }
  }
}
