import { request } from './request'

export const getProfile = () => {
  return request({
    url: 'account/users/me',
    method: 'GET'
  })
}

export const saveProfile = data => {
  return request({
    url: 'account/users/me',
    method: 'PATCH',
    body: Object.keys(data).map(k => {
      return { op: 'replace', path: `/${k}`, value: data[k] || '' }
    })
  })
}

export const sendConfirmEmail = () => {
  return request({
    method: 'POST',
    url: 'account/users/me/sendconfirmemail'
  })
}
