import { Input, Button } from 'components'

export const QuestionPersonMultiple = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	let people = onGetFormAnswer(question.id)
		? onGetFormAnswer(question.id)
		: question.minValue === 2
		? [
				['', '', ''],
				['', '', ''],
		  ]
		: question.minValue === 3
		? [
				['', '', ''],
				['', '', ''],
				['', '', ''],
		  ]
		: [['', '', '']]
	return {
		view() {
			return m('.flex.flex-col', [
				m('label.block.text-sm.font-bold.text-gray-700', question.name),
				people.map((person, ix) => {
					return m('.flex.gap-x-1', [
						m(
							'.grow',
							m(Input, {
								label: 'Name',
								name: `input_${question.id}_0_${ix}`,
								type: 'text',
								required: question.isRequired,
								value: person ? person[0] : '',
								oninput(val) {
									person[0] = val
									onSetFormAnswer(question, people)
								},
							})
						),
						question.personDetailsLabel &&
							m(Input, {
								label: question.personDetailsLabel,
								name: `input_${question.id}_1_${ix}`,
								type: 'text',
								required: question.isRequired,
								value: person ? person[1] : '',
								oninput(val) {
									person[1] = val
									onSetFormAnswer(question, people)
								},
							}),
						question.personDetailsLabel2 &&
							m(Input, {
								label: question.personDetailsLabel2,
								name: `input_${question.id}_2_${ix}`,
								type: 'text',
								required: question.isRequired && question.personDetailsLabel2 !== 'Pronouns',
								value: person ? person[2] : '',
								oninput(val) {
									person[2] = val
									onSetFormAnswer(question, people)
								},
							}),
					])
				}),
				m(
					'.w-32',
					question.maxValue !== people.length &&
						m(
							Button,
							{
								type: 'button',
								size: 'xs',
								variant: 'positive',
								onclick() {
									people.push(['', '', ''])
									m.redraw()
								},
							},
							'Add another person'
						)
				),
			])
		},
	}
}
