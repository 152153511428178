export const messages = {
	app: {
		loggedIn: 'Successfully logged in!',
		edit: 'Edit',
		cancel: 'Cancel',
		save: 'Save',
		updated: 'Updated',
		remove: 'Remove',
		dashboard: 'Dashboard',
		logOut: 'Log out',
		settings: 'Settings',
		search: 'Search',
	},
	logIn: {
		title: 'Log in',
		email: 'Username / Email Address',
		password: 'Password',
		signUpLink: 'No account? Register here',
		action: 'Log in',
		forgotten: 'Forgotten your sign in details?',
		forgottenInstruction: 'Email the admins',
		genericError: 'There was an error logging in.',
	},
	register: {
		title: 'Register',
		email: 'Email Address',
		password: 'Password',
		logInLink: 'Log in',
		action: 'Register',
	},
	forgottenPassword: {
		title: 'Reset password',
		email: 'Email Address',
		logInLink: 'Log in',
		action: 'Request password change',
		resetAction: 'Set new password',
	},
	users: {
		title: 'Users',
		addTitle: 'Add New User',
		name: 'Name',
		email: 'Email',
		submit: 'Submit',
		created: 'User added',
		updated: 'User updated',
		status: 'Status',
	},
	settings: {
		title: 'Settings',
		emailNotConfirmed: 'Email not confirmed',
		emailNotConfirmedText:
			'You have yet to confirm your email address. Please check your inbox for further instructions or click resend below.',
		resendEmailConfirmation: 'Resend',
		profileTitle: 'Your Profile',
		profileEmail: 'Email address:',
		profileFirstName: 'First name:',
		profileLastName: 'Last name:',
		profileCompany: 'Company:',
		profilePosition: 'Position:',
		profilePhoneNumber: 'Phone number:',
		profileSubmit: 'Update',
		appTitle: 'App settings',
		appSubmit: 'Update App Settings',
		profileSaved: 'Profile updated',
	},
}
