import { Input } from 'components'

export const QuestionPerson = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	let person = onGetFormAnswer(question.id) ? onGetFormAnswer(question.id) : ['', '', '']
	return {
		view() {
			return m('.flex.flex-col', [
				m('label.block.text-sm.font-bold.text-gray-700', question.name),
				m('.flex.gap-x-1', [
					m(
						'.grow',
						m(Input, {
							label: 'Name',
							name: `input_${question.id}_0`,
							type: 'text',
							required: question.isRequired,
							value: person ? person[0] : '',
							oninput(val) {
								person[0] = val
								onSetFormAnswer(question, person)
							},
						})
					),
					m(Input, {
						label: question.personDetailsLabel,
						name: `input_${question.id}_1`,
						type: 'text',
						required: question.isRequired,
						value: person ? person[1] : '',
						oninput(val) {
							person[1] = val
							onSetFormAnswer(question, person)
						},
					}),
					m(Input, {
						label: question.personDetailsLabel2,
						name: `input_${question.id}_2`,
						type: 'text',
						required: question.isRequired && question.personDetailsLabel2 !== 'Pronouns',
						value: person ? person[2] : '',
						oninput(val) {
							person[2] = val
							onSetFormAnswer(question, person)
						},
					}),
				]),
			])
		},
	}
}
