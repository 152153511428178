import { Card, Heading, Svg, Input, Button, Breadcrumb, Tooltip, LoadingFullScreen } from 'components'
import { validate, getError, displayErrors } from 'validators/utils'
import { getEntryFormBySlug, saveEntryForm, submitEntryForm, getEntryFormAnswers, getEntryFormFees } from 'api/forms'
import {
	QuestionText,
	QuestionTextArea,
	QuestionSelect,
	QuestionYesNo,
	QuestionCheckbox,
	QuestionUpload,
	QuestionPerson,
	QuestionPersonMultiple,
	QuestionCustomMultiple,
} from '../../Forms/QuestionTypes'
import logo from 'assets/logo.svg'
import alertIcon from 'assets/icons/outline/question-mark-circle.svg'
import { PaymentDialog } from './PaymentDialog'

import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'

const formData = {}

export const EntryForm = ({ attrs: { slug, id, z } }) => {
	let errors = []
	let serverErrors
	let loading, submitLoading
	let form
	let answers
	let fees
	let savingPayment
	const paymentOpen = flyd.stream(false)
	const autoSaveMs = 15 * 60 * 1000
	const dtFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }

	getEntryFormBySlug(slug)
		.then((res) => {
			if (!id) {
				answers = res.questions.map((q) => {
					return { questionId: q.id, value: null }
				})

				form = res
				form.questionSections = []
				let curSection
				form.questions.forEach((x) => {
					if (x.questionTypeId === 8) {
						form.questionSections[x.sortOrder] = [x]
						curSection = x.sortOrder
					} else {
						form.questionSections[curSection].push(x)
					}
				})
			} else {
				getEntryFormAnswers(id, z).then((fa) => {
					if (fa.submissionStatusId !== 1) {
						m.route.set('/entries')
					}
					answers = res.questions.map((q) => {
						const val = fa.answers.find((a) => {
							return a.questionId === q.id
						})
						return { questionId: q.id, value: val ? val.value : null }
					})

					form = res
					form.updatedDate = fa.updatedDate
					form.questionSections = []
					let curSection
					form.questions.forEach((x) => {
						if (x.questionTypeId === 8) {
							form.questionSections[x.sortOrder] = [x]
							curSection = x.sortOrder
						} else {
							form.questionSections[curSection].push(x)
						}
					})
				})
			}

			if (!id) {
				saveEntryForm(form.id, {
					formId: form.id,
					entryFormId: id,
					answers,
				}).then((res) => {
					id = res.id
					var pageUrl = `${window.location.pathname}/edit/${id}`
					window.history.pushState('', '', pageUrl)
					notification.success({ title: 'Entry Form Created' })
				})
			}
		})
		.catch((err) => {
			serverErrors = ['The form cannot be found']
		})

	const setFormAnswer = (question, val) => {
		answers.find((a) => {
			return a.questionId === question.id
		}).value = typeof val === 'boolean' ? val : JSON.stringify(val)
		m.redraw()
	}

	const getFormAnswer = (questionId) => {
		const val = answers.find((a) => {
			return a.questionId === questionId
		}).value
		return typeof val === 'boolean' ? val : val ? JSON.parse(val) : ''
	}

	const isFormAnswerMatched = (question) => {
		const answer = getFormAnswer(question.visibleQuestionId)
		let response = false
		if (answer) {
			const matchValue = question.visibleQuestionValue
			if ((Array.isArray(answer) && answer.includes(matchValue)) || answer === matchValue) {
				response = true
			} else if (matchValue.indexOf('||')) {
				let matchValueArr = matchValue.split('||')
				matchValueArr.forEach((element) => {
					if ((Array.isArray(answer) && answer.includes(element)) || answer === element) {
						response = true
					}
				})
			}
		}
		return response
	}

	const onFormSubmit = () => {
		submitLoading = true
		if (form) {
			submitEntryForm(
				form.id,
				{
					formId: form.id,
					id: id,
					answers,
				},
				z
			)
				.then((res) => {
					if (res === 'Payment required') {
						getEntryFormFees(id).then((f) => {
							fees = f
							paymentOpen(true)
						})
					} else {
						notification.success({ title: 'Entry Form Submitted' })
						m.route.set('/thank-you')
					}
					submitLoading = false
				})
				.catch((err) => {
					if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
						Sentry.captureException(err)
					}
					notification.error({ title: 'Submission failed' })
					loading = false
				})
		}
	}
	const onFormSave = () => {
		loading = true
		saveEntryForm(form.id, {
			formId: form.id,
			id: id,
			answers,
		})
			.then((res) => {
				notification.success({ title: 'Entry Form Saved' })
				form.updatedDate = res.updatedDate
				loading = false
			})
			.catch((err) => {
				notification.error({ title: 'Saving failed' })
				if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
					Sentry.captureException(err)
				}
				loading = false
			})
	}

	const autoSave = () => {
		if (form && !loading) {
			loading = true
			saveEntryForm(form.id, {
				formId: form.id,
				id: id,
				answers,
			})
				.then((res) => {
					form.updatedDate = res.updatedDate
					loading = false
					setTimeout(autoSave, autoSaveMs)
				})
				.catch((err) => {
					loading = false
					setTimeout(autoSave, autoSaveMs)
				})
		} else {
			setTimeout(autoSave, autoSaveMs)
		}
	}
	setTimeout(autoSave, autoSaveMs)

	const pay = () => {}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entries',
							href: '/entries',
						},
						{
							title: form ? form.name : '...',
						},
					],
				}),
				serverErrors && displayErrors(serverErrors),
				form
					? m('.grid.sm:grid-cols-5', [
							m('.sm:col-span-4.flex.flex-col.sm:flex-row.px-2.sm:px-0.relative.order-2.sm:order-1', [
								m(
									Card,
									{
										//classes: ['sm:w-3/4', 'my-1', 'mx-2'],
									},
									[
										m('.p-6', [
											m(Heading, form.name),
											form.description && m('.text-base.space-y-2.mt-2', m.trust(form.description)),
											m(
												'form',
												{
													onsubmit(e) {
														e.preventDefault()
														onFormSubmit()
													},
												},
												[
													m('.flex.flex-col.mt-6', [
														Object.keys(form.questionSections).map((section) => {
															const sectionQuestions = form.questionSections[section]
															return sectionQuestions[0].visibleQuestionId === null ||
																isFormAnswerMatched(sectionQuestions[0])
																? m('.bg-gray-100.p-1.-mx-2.sm:p-3.sm:-mx-3.rounded.rounded-md.mb-6', [
																		m('h4.text-lg.font-bold.uppercase.pl-1', sectionQuestions[0].name),
																		sectionQuestions.map((question) => {
																			return question.questionTypeId !== 8 &&
																				(question.visibleQuestionId === null || isFormAnswerMatched(question))
																				? m(
																						'.flex.px-2.py-1.sm:px-3.sm:py-4.bg-white.rounded.rounded-md.w-full',
																						{ 'data-q': question.sortOrder },
																						[
																							m('.grow', [
																								question.questionTypeId === 1 || // Text
																								question.questionTypeId === 6 || // Email
																								question.questionTypeId === 9 || // Date
																								question.questionTypeId === 16 // Text (Film Title)
																									? m(QuestionText, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 2 // Multiline Text
																									? m(QuestionTextArea, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 3 || // Dropdown
																									  question.questionTypeId === 5 || // Dropdown multiple
																									  question.questionTypeId === 15 || // Dropdown (Budget)
																									  question.questionTypeId === 17 // Categories
																									? m(QuestionSelect, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 4 // YesNo
																									? m(QuestionYesNo, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 7 // Checkbox
																									? m(QuestionCheckbox, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 10 // File Upload
																									? m(QuestionUpload, {
																											question,
																											formAnswerSetId: id,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 11 // Section Text
																									? m(
																											'div',
																											question.info &&
																												m(
																													'.text-sm.underline-links',
																													m.trust(question.info.replaceAll('\n', '<br>'))
																												)
																									  )
																									: question.questionTypeId === 12 // Person
																									? m(QuestionPerson, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 13 // Person multiple
																									? m(QuestionPersonMultiple, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: question.questionTypeId === 14 // Custom multiple
																									? m(QuestionCustomMultiple, {
																											question,
																											onGetFormAnswer: getFormAnswer,
																											onSetFormAnswer: setFormAnswer,
																									  })
																									: m('p', question.name + ' - Error, unknown question type'),
																								question.questionTypeId !== 11 &&
																									question.info &&
																									m(
																										'.text-sm.italic.text-gray-600.underline-links',
																										m.trust(question.info.replaceAll('\n', '<br>'))
																									),
																							]),

																							m(
																								'.w-8.shrink-0.flex.justify-end',
																								question.help &&
																									m(Tooltip, {
																										tipContent: question.help,
																										icon: alertIcon,
																										colorClass: 'bg-teal-600 text-white',
																									})
																							),
																						]
																				  )
																				: null
																		}),
																  ])
																: null
														}),
													]),
													m('.flex.justify-between.items-center.mt-6', [
														m(
															Button,
															{
																variant: 'info',
																loading: loading,
																onclick: onFormSave,
																type: 'button',
															},
															'Save Progress'
														),
														m(
															Button,
															{
																variant: 'positive',
																loading: submitLoading,
															},
															'Submit Entry'
														),
													]),
												]
											),
										]),
									]
								),
							]),
							m('.sticky.top-0.z-10.sm:relative.w-full.p-4.order-1.sm:order-2.bg-gray-50', [
								form &&
									m(
										'.sticky.flex.flex-col',
										{
											style: 'top:1rem',
										},
										[
											m(
												Button,
												{
													variant: 'info',
													loading: loading,
													onclick: onFormSave,
													type: 'button',
												},
												'Save Progress'
											),
											form.updatedDate &&
												m(
													'p.text-xs.text-gray-600',
													`Last saved: ${DateTime.fromISO(form.updatedDate).toLocaleString(dtFormat)}`
												),
										]
									),
							]),
					  ])
					: m(LoadingFullScreen),

				paymentOpen() &&
					m(PaymentDialog, {
						open: paymentOpen,
						onsubmit: pay,
						loading: savingPayment,
						fees: fees,
					}),
			]
		},
	}
}
