import { request } from './request'
import { hasStoredToken } from 'store/auth'

export const getForms = (params) => {
	return request({
		url: 'forms',
		params: {
			pagesize: 25,
			...params,
		},
		method: 'GET',
	})
}

export const getForm = (id) => {
	return request({
		url: `forms/${id}`,
		method: 'GET',
	})
}

export const getFormBySlug = (slug) => {
	return m.request({
		url: `https://${process.env.API_DOMAIN}/forms/public`,
		method: 'GET',
		params: {
			slug,
		},
	})
}

export const getEntryForms = () => {
	return request({
		url: `entryforms`,
		method: 'GET',
	})
}

export const getEntryForm = (id) => {
	return request({
		url: `entryforms/${id}`,
		method: 'GET',
	})
}

export const getEntryFormBySlug = (id) => {
	return request({
		url: `entryforms/slug/${id}`,
		method: 'GET',
	})
}

export const submitForm = (id, model) => {
	return m.request({
		url: `https://${process.env.API_DOMAIN}/forms/public/${id}/submit`,
		method: 'POST',
		body: model,
	})
}

export const getQuestionTypes = () => {
	return request({
		url: `forms/question-types`,
		method: 'GET',
	})
}
export const getFormTypes = () => {
	return request({
		url: `forms/form-types`,
		method: 'GET',
	})
}

export const saveEntryForm = (formId, model) => {
	return request({
		url: `entryforms/${formId}/save`,
		method: 'PUT',
		body: model,
	})
}
export const submitEntryForm = (formId, model, z) => {
	return request({
		url: `entryforms/${formId}/submit`,
		method: 'POST',
		body: model,
		params: {
			z: z ? z : null,
		},
	})
}

export const uploadEntryFormFile = (file, formAnswerSetId, questionId) => {
	const formData = new FormData()
	formData.append('file', file)
	return request(
		{
			url: `entryforms/upload/${formAnswerSetId}/${questionId}`,
			method: 'POST',
			body: formData,
		},
		{
			contentType: false,
		}
	)
}

export const getEntryFormAnswers = (id, z) => {
	return request({
		url: `entryforms/formanswers/${id}`,
		method: 'GET',
		params: {
			z: z ? z : null,
		},
	})
}
export const getUserEntryFormAnswers = () => {
	return request({
		url: `entryforms/entries`,
		method: 'GET',
	})
}

export const getEntryFormFees = (id) => {
	return request({
		url: `entryforms/formanswers/${id}/entry-fees`,
		method: 'GET',
	})
}
export const getEntryFormFeesUrl = (id) => {
	return request({
		url: `entryforms/formanswers/${id}/entry-fees/redirect`,
		method: 'GET',
	})
}

export const setEntryFormAnswersPaid = (id) => {
	return request({
		url: `entryforms/formanswers/${id}/entry-fees/paid`,
		method: 'POST',
	})
}
