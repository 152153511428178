import { getProfile as getProfileRequest, saveProfile } from 'api/profile'

const data = {
	pending: flyd.stream(false),
	profile: initialiseProfile(),
}

function initialiseProfile() {
	try {
		const localStorageProfile = JSON.parse(localStorage.getItem('profile'))
		return localStorageProfile || null
	} catch (e) {
		clearProfile()
		return null
	}
}

export function getProfile() {
	data.pending(true)
	return getProfileRequest()
		.then(setProfile)
		.then(() => {
			data.pending(false)
			return data.profile
		})
}

export function updateProfile(prof) {
	return saveProfile(prof).then(getProfile)
}

function setProfile(prof) {
	data.profile = prof
	localStorage.setItem('profile', JSON.stringify(prof))
}

export function profile() {
	return data.profile
}

export function profileIsPending() {
	return data.pending()
}

export function clearProfile() {
	localStorage.removeItem('profile')
	if (data) {
		data.profile = null
	}
}

export const isAdmin = () => {
	return profile() && profile().roles.includes('Administrator')
}

export const isVotingAdmin = () => {
	return profile() && profile().roles.includes('VotingAdministrator')
}

export const isEntrant = () => {
	return profile() && profile().roles.includes('Entrant')
}
