import { Input } from 'components'

export const QuestionText = ({ attrs: { question, onGetFormAnswer, onSetFormAnswer } }) => {
	return {
		view() {
			return m(Input, {
				label: question.name,
				name: `input_${question.id}`,
				type: question.questionTypeId === 6 ? 'email' : question.questionTypeId === 9 ? 'date' : 'text',
				required: question.isRequired,
				value: onGetFormAnswer(question.id),
				min: question.minValue ? question.minValue : null,
				max: question.maxValue ? question.maxValue : null,
				oninput(val) {
					onSetFormAnswer(question, val)
				},
			})
		},
	}
}
