import { Card, Heading, Input, Button, Link, Svg } from 'components'
import { resetPassword } from 'api/auth'
import { getError, displayErrors } from 'validators/utils'
import { notification } from 'utils/notifications'

import logo from 'assets/logo.svg'

export const SetPassword = ({ attrs: { code, email } }) => {
	let errors = []
	let serverErrors
	let loading
	let success = false
	if (!code && window.location.search) {
		code = new URLSearchParams(window.location.search).get('code')
	}
	let requestToken = false
	const formData = {
		resetCode: code,
		usernameOrEmail: email,
		password: '',
	}
	const triggerPasswordReset = () => {
		errors = []
		loading = true
		resetPassword(formData)
			.then((res) => {
				loading = false
				success = true
			})
			.catch((err) => {
				loading = false
				serverErrors = err.response.Errors
				notification.error({ title: 'Password setting failed - link is invalid or has expired' })
				requestToken = true
			})
	}
	return {
		view() {
			return m('.h-full.flex.flex-col.justify-center.items-center.bg-black.h-screen', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-md', 'mt-3'],
					},
					[
						m('.p-6', [
							m(Heading, 'Create a Password'),
							success
								? m('div', [
										m('p', 'Password set. Please login to acces your new account'),
										m(
											Button,
											{
												type: 'button',
												onclick() {
													m.route.set('/login')
												},
											},
											'Login'
										),
								  ])
								: m(
										'form.grid.gap-6',
										{
											onsubmit(e) {
												e.preventDefault()
												triggerPasswordReset()
											},
										},
										[
											m(Input, {
												label: t('forgottenPassword', 'email'),
												value: formData.usernameOrEmail,
												type: 'readonly',
											}),
											m(Input, {
												label: 'New ' + t('register', 'password'),
												value: formData.password,
												type: 'password',
												required: true,
												pattern: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
												errorMessage: getError(errors, 'password'),
												oninput(val) {
													formData.password = val
												},
											}),
											displayErrors(serverErrors),
											m('.flex.justify-between.items-center', [
												m(
													Button,
													{
														variant: 'positive',
														loading,
													},
													'Set Password'
												),
												requestToken &&
													m(
														Link,
														{
															classes: ['text-orange-900', 'underline'],
															href: '/forgotten-password',
															options: { replace: true },
														},
														'Request New Link'
													),
											]),
										]
								  ),
						]),
					]
				),
			])
		},
	}
}
