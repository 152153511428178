export const Svg = () => {
  let el
  return {
    oncreate ({ dom, attrs: { classes = [] } }) {
      el = dom
      if (el) {
        el.classList.remove(...el.classList)
        el.classList.add(...classes)
        const titles = el.querySelectorAll('title')
        titles.forEach(t => {
          t.parentNode.removeChild(t)
        })
      }
    },
    onupdate ({ dom, attrs: { classes = [] } }) {
      el = dom
      if (el) {
        el.classList.remove(...el.classList)
        el.classList.add(...classes, 'pointer-events-none')
      }
    },
    view ({ children }) {
      return m.trust(children[0])
    }
  }
}
