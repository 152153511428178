import { Dialog2, LoadingFullScreen, Select, Input, TextArea, Checkbox } from 'components'
import { DateTime } from 'luxon'
import { formatMoney } from 'utils'
import { getEntryFormFeesUrl } from 'api/forms'

export const PaymentDialog = ({ attrs: { loading, formId, question } }) => {
	loading = false

	const formData = {}

	return {
		view({ attrs: { loading, fees, open, onsubmit } }) {
			return m(
				Dialog2,
				{
					open,
					saving: loading,
					save() {
						loading = true
						getEntryFormFeesUrl(fees.id).then((res) => {
							window.location.href = res
						})
					},
					saveLabel: 'Checkout',
					cancelLabel: 'Cancel',
					heading: 'Entry Fees',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[
					m(
						'.flex',
						'In order for your entry to be accepted, you will need to pay the following entry fee(s). Please checkout below to make your payment by credit/debit card.'
					),
					m('p', [
						'If you have any questions, please get in touch on ',
						m('a[href="mailto:hello@bifa.film"]', 'hello@bifa.film'),
						'.',
					]),
					fees
						? m('section.rounded-lg.bg-gray-50.px-4.py-6.sm:p-6.lg:mt-0.lg:p-8', [
								m('dl.space-y-4', [
									fees.entryFee &&
										m('div.flex.items-center.justify-between', [
											m('dt.text-sm.text-gray-600', 'Entry Fee'),
											m('dd.text-sm.font-medium.text-gray-900', formatMoney(fees.entryFee.amount)),
										]),

									fees.eventiveEntryFee &&
										m('div.flex.items-center.justify-between.border-t.border-gray-200.pt-4', [
											m('dt.text-sm.text-gray-600', 'Eventive Fee'),
											m('dd.text-sm.font-medium.text-gray-900', formatMoney(fees.eventiveEntryFee.amount)),
										]),
									(fees.entryFee || fees.eventiveEntryFee) &&
										m('div.flex.items-center.justify-between.border-t.border-gray-200.pt-4', [
											m('dt.text-sm.text-gray-500', 'Includes VAT (20%)'),
											m(
												'dd.text-sm.font-medium.text-gray-700',
												formatMoney(
													parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
														parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0) -
														(parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
															parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0)) /
															1.2
												)
											),
										]),
									m('div.flex.items-center.justify-between.border-t.border-gray-200.pt-4', [
										m('dt.text-base.font-medium.text-gray-900', 'Fees total'),
										m(
											'dd.text-base.font-medium.text-gray-900',
											formatMoney(
												parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
													parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0)
											)
										),
									]),
								]),
						  ])
						: m(LoadingFullScreen),
				]
			)
		},
	}
}
