import { Breadcrumb, Table, Cell, Button, Pagination, LoadingFullScreen } from 'components'
import { DateTime } from 'luxon'

import { getEntryForms, getUserEntryFormAnswers } from 'api/forms'
import { getUserFilms } from 'api/films'

export const Entries = () => {
	const dtFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }
	let entryForms
	getEntryForms().then((p) => {
		if (p) {
			entryForms = p.filter((f) => {
				return DateTime.fromISO(f.closingDate) > DateTime.now()
			})
		}
	})
	let userEntries
	let userEntriesLoading = true
	getUserEntryFormAnswers().then((p) => {
		if (p) {
			userEntries = p
			userEntriesLoading = false
		}
	})

	let userFilms
	let userFilmsLoading = true
	getUserFilms().then((p) => {
		if (p) {
			userFilms = p
			userFilmsLoading = false
		}
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entries',
						},
					],
				}),
				m('.my-4.px-4', [
					m('div.shadow.h-full.border-b.border-gray-200.bg-white.sm:rounded-lg', [
						m('.p-4.text-lg', 'Your Entries'),
						userEntriesLoading && m('.px-4.pb-4', `Loading your entries...`),
						userEntries && userEntries.length === 0 && !userEntriesLoading
							? m('.px-4.pb-4', `No entries found.`)
							: null,
						userEntries && userEntries.length
							? m(
									'ul.grid.grid-cols-1.gap-6.sm:grid-cols-2.md:grid-cols-3.lg:grid-cols-4[role="list"].p-4.bg-gray-50',
									[
										userEntries.map((entryForm) => {
											return m(
												'li.col-span-1.flex.flex-col.divide-y.divide-gray-200.rounded-lg.bg-white.text-center.shadow',
												[
													m('div.flex.flex-1.flex-col.px-4.py-2', [
														m('dl.flex.flex-grow.flex-col.justify-between', [
															m('dt.sr-only', 'Form'),
															m('dd.text-sm.text-gray-500', entryForm.form.shortName),
														]),
														m(
															'h3.mt-2.text-base.font-bold.text-gray-900',
															entryForm.filmTitle ? entryForm.filmTitle : 'Untitled Film'
														),
														m('dl.mt-1.flex.flex-grow.flex-col.justify-between', [
															m('dt.sr-only', 'Status'),
															m(
																'dd.mt-1',
																m(
																	'span.inline-flex.items-center.rounded-full.px-2.py-1.text-xs.font-medium.ring-1.ring-inset',
																	{
																		class:
																			entryForm.submissionStatusId == 1
																				? 'bg-orange-100 text-orange-700 ring-orange-300'
																				: 'bg-green-100 text-green-700 ring-green-600',
																	},
																	entryForm.submissionStatusName
																)
															),
															m('dl.mt-1.flex.gap-x-2.justify-center', [
																m('dt.text-xs.text-gray-500', 'Created:'),
																m(
																	'dd.text-xs.text-gray-500',
																	DateTime.fromISO(entryForm.createdDate).toLocaleString(dtFormat)
																),
															]),
														]),
													]),
													m(
														'div',
														m('div.-mt-px.flex.divide-x.divide-gray-200', [
															m(
																'div.-ml-px.flex.w-0.flex-1',
																m(
																	'a.relative.inline-flex.w-0.flex-1.items-center.justify-center.gap-x-3.rounded-b-lg.border.border-transparent.py-2.text-sm.font-semibold.bg-blue-500.text-white.hover:bg-blue-400',
																	{
																		href:
																			entryForm.submissionStatusId == 1 &&
																			DateTime.fromISO(entryForm.closingDate) > DateTime.now()
																				? `/entries/forms/${entryForm.form.slug}/edit/${entryForm.id}`
																				: `/entries/forms/${entryForm.form.slug}/view/${entryForm.id}`,
																	},
																	[
																		entryForm.submissionStatusId == 1 &&
																		DateTime.fromISO(entryForm.closingDate) > DateTime.now()
																			? ' Continue Entry '
																			: 'View Entry',
																	]
																),
																entryForm.submissionStatusId == 1 &&
																	m(
																		'a.relative.inline-flex.w-0.flex-1.items-center.justify-center.gap-x-3.rounded-b-lg.border.border-transparent.py-2.text-sm.font-semibold.bg-blue-500.text-white.hover:bg-blue-400',
																		{
																			href:
																				entryForm.submissionStatusId == 1 &&
																				DateTime.fromISO(entryForm.closingDate) > DateTime.now()
																					? `/entries/forms/${entryForm.form.slug}/edit/${entryForm.id}`
																					: `/entries/forms/${entryForm.form.slug}/view/${entryForm.id}`,
																		},
																		[
																			entryForm.submissionStatusId == 1 &&
																			DateTime.fromISO(entryForm.closingDate) > DateTime.now()
																				? ' Continue Entry '
																				: 'View Entry',
																		]
																	)
															),
														])
													),
												]
											)
										}),
									]
							  )
							: null,
					]),
				]),
				m('.my-4.px-4', [
					m('div.shadow.h-full.border-b.border-gray-200.bg-white.sm:rounded-lg', [
						m('.p-4.text-lg', 'Your Films'),
						userFilmsLoading && m('.px-4.pb-4', `Loading your films...`),
						userFilms && userFilms.length === 0 && !userFilmsLoading ? m('.px-4.pb-4', `No films found.`) : null,
						userFilms && userFilms.length
							? m(
									'ul.grid.grid-cols-1.gap-6.sm:grid-cols-2.md:grid-cols-3.lg:grid-cols-4[role="list"].p-4.bg-gray-50',
									[
										userFilms.map((film) => {
											return m(
												'li.col-span-1.flex.flex-col.divide-y.divide-gray-200.rounded-lg.bg-white.text-center.shadow',
												[
													m('div.flex.flex-1.flex-col.px-4.py-2', [
														m('dl.flex.flex-grow.flex-col.justify-between', [
															m('dt.sr-only', 'Type'),
															m('dd.text-sm.text-gray-500', film.isShort ? 'Shorts' : 'Features'),
														]),
														m('h3.mt-2.text-base.font-bold.text-gray-900', film.title),
														m('dl.mt-1.flex.flex-grow.flex-col.justify-between', [
															film.isSignedOff
																? m('dl.mt-1.flex.gap-x-2.justify-center', [
																		m('dt.text-xs.text-gray-500', 'Confirmed:'),
																		m(
																			'dd.text-xs.text-gray-500',
																			DateTime.fromISO(film.signedOffDate).toLocaleString(dtFormat)
																		),
																  ])
																: m('dt.text-xs.text-gray-500', 'Unconfirmed'),
															,
														]),
													]),
													m(
														'div',
														m('div.-mt-px.flex.divide-x.divide-gray-200', [
															m(
																'div.-ml-px.flex.w-0.flex-1',
																m(
																	'a.relative.inline-flex.w-0.flex-1.items-center.justify-center.gap-x-3.rounded-b-lg.border.border-transparent.py-2.text-sm.font-semibold.bg-blue-500.text-white.hover:bg-blue-400',
																	{
																		href: `/films/${film.id}`,
																	},
																	['View Film']
																)
															),
														])
													),
												]
											)
										}),
									]
							  )
							: null,
					]),
				]),
				entryForms &&
					m('.my-4.px-4', [
						m('div.shadow.h-full.border-b.border-gray-200.bg-white.sm:rounded-lg', [
							m('.p-4.text-lg', 'Entry Forms'),
							m('.p-4.text-base', `Select an entry form below to create a new entry to this year's awards.`),
							m('.p-4.flex.gap-x-3', [
								entryForms.map((entryForm) => {
									return m(Button, { href: '/entries/forms/' + entryForm.slug }, entryForm.name)
								}),
							]),
						]),
					]),
			]
		},
	}
}
