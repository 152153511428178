import Joi from 'joi'

export const schema = Joi.object().keys({
  email: Joi.string()
    // .email({ tlds: { allow: false } })
    .required()
    .messages({
      // 'string.email': 'Must be a valid email address',
      'string.empty': 'Email address / Username is required',
      'any.required': 'Email address / Username is required'
    }),
  password: Joi.string()
    .required()
    .messages({
      'string.empty': 'Password is required',
      'any.required': 'Password is required'
    })
})
