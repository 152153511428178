import { Layout } from 'components/Layout'
import { Register } from 'pages/Register'
import { RegisterSuccess } from 'pages/Register/Success'
import { Login } from 'pages/Login'
import { ForgottenPassword } from 'pages/ForgottenPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { SetPassword } from 'pages/SetPassword'
import { Settings } from 'pages/Settings'
import { Logout } from 'pages/Logout'
import { Dashboard } from 'pages/Dashboard'
import { Form } from 'pages/Forms'
import { Entries } from './pages/Entries'
import { EntryForm } from './pages/Entries/Forms'
import { EntryFormView } from './pages/Entries/Forms/View'
import { EntryFormPaid } from './pages/Entries/Forms/Paid'
import { ThankYou } from './pages/Forms/ThankYou'
import { Film } from './pages/Films'

m.route.prefix = ''

const Routes = {
	'/register': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(Register, vnode.attrs)]
			)
		},
	},
	'/register-success': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(RegisterSuccess, vnode.attrs)]
			)
		},
	},
	'/login': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
					allowDelegate: true,
				},
				[m(Login, vnode.attrs)]
			)
		},
	},
	'/forgotten-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ForgottenPassword, vnode.attrs)]
			)
		},
	},
	'/reset-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(ResetPassword, vnode.attrs)]
			)
		},
	},
	'/set-password': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				[m(SetPassword, vnode.attrs)]
			)
		},
	},
	'/': {
		render(vnode) {
			return m(
				Layout,
				m(Dashboard, {
					...vnode.attrs,
				})
			)
		},
	},
	'/forms/:slug': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				m(Form, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/thank-you': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: true,
				},
				m(ThankYou, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/entries': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(Entries, {
					...vnode.attrs,
				})
			)
		},
	},
	'/entries/forms/:slug': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(EntryForm, {
					key: vnode.attrs.slug,
					...vnode.attrs,
				})
			)
		},
	},
	'/entries/forms/:slug/edit/:id': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(EntryForm, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/entries/forms/:slug/view/:id': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(EntryFormView, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/entries/forms/:slug/paid/:id': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(EntryFormPaid, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/settings': {
		render(vnode) {
			return m(
				Layout,
				m(Settings, {
					...vnode.attrs,
				})
			)
		},
	},
	'/films/:id': {
		render(vnode) {
			return m(
				Layout,
				{
					hideNav: false,
				},
				m(Film, {
					key: vnode.attrs.id,
					...vnode.attrs,
				})
			)
		},
	},
	'/logout': Logout,
}

const DefaultRoute = '/'

export { Routes, DefaultRoute }
