import { Card, Heading, Svg, Input, Button, Breadcrumb, Tooltip, LoadingFullScreen } from 'components'
import { PaymentDialog } from './PaymentDialog'
import { validate, getError, displayErrors } from 'validators/utils'
import { getEntryFormBySlug, getEntryFormAnswers, getEntryFormFees } from 'api/forms'

import { notification } from 'utils/notifications'
import { DateTime } from 'luxon'
import { formatMoney } from 'utils'

const formData = {}

export const EntryFormView = ({ attrs: { slug, id } }) => {
	let errors = []
	let serverErrors
	let form
	let answers
	let fees
	let savingPayment
	const paymentOpen = flyd.stream(false)
	const dtFormat = { ...DateTime.DATETIME_SHORT, year: '2-digit' }

	getEntryFormBySlug(slug)
		.then((res) => {
			getEntryFormAnswers(id).then((fa) => {
				answers = res.questions.map((q) => {
					const val = fa.answers.find((a) => {
						return a.questionId === q.id
					})
					return { questionId: q.id, value: val ? val.value : null }
				})

				form = res
				form.filmTitle = fa.filmTitle ? fa.filmTitle : 'Untitled Film'
				form.updatedDate = fa.updatedDate
				form.createdDate = fa.createdDate
				form.submissionStatusId = fa.submissionStatusId
				form.submissionStatusName = fa.submissionStatusName
				form.questionSections = []
				let curSection
				form.questions.forEach((x) => {
					if (x.questionTypeId === 8) {
						form.questionSections[x.sortOrder] = [x]
						curSection = x.sortOrder
					} else {
						form.questionSections[curSection].push(x)
					}
				})

				getEntryFormFees(id).then((f) => {
					fees = f
				})
			})
		})
		.catch((err) => {
			serverErrors = ['The form cannot be found']
		})

	const getFormAnswer = (questionId) => {
		const val = answers.find((a) => {
			return a.questionId === questionId
		})
			? answers.find((a) => {
					return a.questionId === questionId
			  }).value
			: JSON.stringify('')
		return typeof val === 'boolean' ? val : val ? JSON.parse(val) : ''
	}

	const isFormAnswerMatched = (question) => {
		const answer = getFormAnswer(question.visibleQuestionId)
		let response = false
		if (answer) {
			const matchValue = question.visibleQuestionValue
			if ((Array.isArray(answer) && answer.includes(matchValue)) || answer === matchValue) {
				response = true
			} else if (matchValue.indexOf('||')) {
				let matchValueArr = matchValue.split('||')
				matchValueArr.forEach((element) => {
					if ((Array.isArray(answer) && answer.includes(element)) || answer === element) {
						response = true
					}
				})
			}
		}
		return response
	}

	const pay = () => {}

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: 'Entries',
							href: '/entries',
						},
						{
							title: form ? form.name : '...',
						},
						{
							title: 'View',
						},
					],
				}),
				serverErrors && displayErrors(serverErrors),
				form
					? m('.grid.sm:grid-cols-5', [
							m('.sm:col-span-4.flex.flex-col.sm:flex-row.px-2.sm:px-0.relative.order-2.sm:order-1', [
								m(
									Card,
									{
										//classes: ['sm:w-3/4', 'my-1', 'mx-2'],
									},
									[
										m('.p-6', [
											m(Heading, form.name),
											m('div', {}, [
												m('.flex.flex-col.mt-6', [
													Object.keys(form.questionSections).map((section) => {
														const sectionQuestions = form.questionSections[section]
														return sectionQuestions[0].visibleQuestionId === null ||
															isFormAnswerMatched(sectionQuestions[0])
															? m('.bg-gray-100.p-1.-mx-2.sm:p-3.sm:-mx-3.rounded.rounded-md.mb-3', [
																	m('h4.text-lg.font-bold.uppercase.pl-1', sectionQuestions[0].name),
																	sectionQuestions.map((question) => {
																		return question.questionTypeId !== 8 &&
																			question.questionTypeId !== 11 &&
																			(question.visibleQuestionId === null || isFormAnswerMatched(question))
																			? m(
																					'.flex.px-2.py-1.sm:px-3.sm:py-4.bg-white.rounded.rounded-md.w-full',
																					{ 'data-q': question.sortOrder },
																					[
																						m('.grow', [
																							m('.flex.flex-col', [
																								m(`label.block.text-sm.font-bold.text-gray-700`, question.name),
																								question.questionTypeId === 1 || // Text
																								question.questionTypeId === 6 || // Email
																								question.questionTypeId === 9 || // Date
																								question.questionTypeId === 16 // Text (Film Title)
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 2 // Multiline Text
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 3 || // Dropdown
																									  question.questionTypeId === 5 || // Dropdown multiple
																									  question.questionTypeId === 15 || // Dropdown (Budget)
																									  question.questionTypeId === 17 // Categories
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 4 // YesNo
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 7 // Checkbox
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 10 // File Upload
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 12 // Person
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 13 // Person multiple
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: question.questionTypeId === 14 // Custom multiple
																									? m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id))
																									: m('p.bg-gray-50.py-1.px-2', getFormAnswer(question.id)),
																							]),
																						]),
																					]
																			  )
																			: null
																	}),
															  ])
															: null
													}),
												]),
											]),
										]),
									]
								),
							]),
							m('.sticky.top-0.z-10.sm:relative.w-full.p-4.order-1.sm:order-2.bg-gray-50', [
								form &&
									m(
										'.sticky.flex.flex-col',
										{
											style: 'top:1rem',
										},
										[
											m('dl.mt-1.flex.flex-grow.flex-col.justify-between', [
												m('p.text-center', form.filmTitle),
												m('dt.sr-only', 'Status'),
												m(
													'dd.mt-1.text-center',
													m(
														'span.inline-flex.items-center.rounded-full.px-2.py-1.text-xs.font-medium.ring-1.ring-inset',
														{
															class:
																form.submissionStatusId == 1
																	? 'bg-orange-100 text-orange-700 ring-orange-300'
																	: 'bg-green-100 text-green-700 ring-green-600',
														},
														form.submissionStatusName
													)
												),
												m('dl.mt-1.flex.gap-x-2.justify-center', [
													m('dt.text-xs.text-gray-500', 'Created:'),
													m('dd.text-xs.text-gray-500', DateTime.fromISO(form.createdDate).toLocaleString(dtFormat)),
												]),

												fees
													? m('section.rounded-lg.bg-white.mt-2.px-2.py-1', [
															m('.text-sm.font-bold', 'Fees'),
															m('dl.space-y-1', [
																fees.entryFee &&
																	m('div.flex.items-center.justify-between', [
																		m('dt.text-xs.text-gray-600', 'Entry Fee'),
																		m('dd.text-xs.font-medium.text-gray-900', formatMoney(fees.entryFee.amount)),
																	]),

																fees.eventiveEntryFee &&
																	m('div.flex.items-center.justify-between.border-t.border-gray-200', [
																		m('dt.text-xs.text-gray-600', 'Eventive Fee'),
																		m(
																			'dd.text-xs.font-medium.text-gray-900',
																			formatMoney(fees.eventiveEntryFee.amount)
																		),
																	]),
																(fees.entryFee || fees.eventiveEntryFee) &&
																	m('div.flex.items-center.justify-between.border-t.border-gray-200', [
																		m('dt.text-xs.text-gray-500', 'Includes VAT (20%)'),
																		m(
																			'dd.text-xs.font-medium.text-gray-700',
																			formatMoney(
																				parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
																					parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0) -
																					(parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
																						parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0)) /
																						1.2
																			)
																		),
																	]),
																m('div.flex.items-center.justify-between.border-t.border-gray-200', [
																	m('dt.text-sm.font-medium.text-gray-900', 'Fees total'),
																	m(
																		'dd.text-sm.font-medium.text-gray-900',
																		formatMoney(
																			parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
																				parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0)
																		)
																	),
																]),
															]),
													  ])
													: null,
												fees
													? m('section.rounded-lg.bg-white.mt-2.px-2.py-1', [
															m('.text-sm.font-bold.mb-1', 'Payments'),
															fees.payments && fees.payments.length
																? m('dl.space-y-1', [
																		fees.payments.map((payment) => {
																			return m('div.flex.items-center.justify-between', [
																				m(
																					'dt.text-xs.text-gray-600',
																					DateTime.fromISO(payment.createdDate).toLocaleString()
																				),
																				m('dd.text-xs.font-medium.text-gray-900', formatMoney(payment.amount)),
																			])
																		}),
																  ])
																: parseFloat(fees.entryFee ? fees.entryFee.amount : 0) +
																		parseFloat(fees.eventiveEntryFee ? fees.eventiveEntryFee.amount : 0) >
																  0
																? m(
																		Button,
																		{
																			type: 'button',
																			size: 'sm',
																			block: true,
																			onclick() {
																				paymentOpen(true)
																			},
																		},
																		'Pay now'
																  )
																: null,
													  ])
													: null,
											]),
										]
									),
							]),
					  ])
					: m(LoadingFullScreen),
				paymentOpen() &&
					m(PaymentDialog, {
						open: paymentOpen,
						onsubmit: pay,
						loading: savingPayment,
						fees: fees,
					}),
			]
		},
	}
}
