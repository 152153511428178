import { Portal, Svg, Button } from 'components'
import xIcon from 'assets/icons/solid/x.svg'

export const Dialog2 = ({ attrs: { open } }) => {
	const closeDialog = () => {
		open(false)
		m.redraw()
	}
	let removeConfirm = false
	let useAction = false

	open.map((val) => {
		if (val === false) {
			document.removeEventListener('backbutton', closeDialog)
		}
	})
	return {
		oncreate({ dom, attrs: { open } }) {
			document.addEventListener('keyup', (e) => {
				if (e.keyCode === 27) {
					closeDialog()
				}
			})
			document.addEventListener('backbutton', closeDialog)
			setTimeout(() => {
				m.redraw()
			}, 300)
		},
		view({
			attrs: {
				open,
				classes = [],
				save,
				action,
				heading,
				saving,
				remove,
				removeText,
				removeHide,
				saveLabel,
				actionLabel,
				cancelLabel,
			},
			children,
		}) {
			return m(
				Portal,
				{
					onbeforeremove(dom) {
						dom.classList.add('dialogFadeOut', 'overflow-hidden')
						dom.children[0].classList.add('slideOutDown')
						m.redraw()
						return new Promise((resolve) => {
							dom.addEventListener('animationend', resolve)
						})
					},
				},
				[
					m(
						'.fixed.z-40.inset-0.overflow-y-auto',
						{
							// onclick () {
							//   open(!open())
							// }
						},
						[
							m('.flex.items-end.justify-center.min-h-screen.pt-4.px-4.pb-20.text-center.sm:block.sm:p-0', [
								m(".fixed.inset-0.bg-gray-500.bg-opacity-75.transition-opacity[aria-hidden='true']"),
								m("span.hidden.sm:inline-block.sm:align-middle.sm:h-screen[aria-hidden='true']", m.trust('&#8203;')),
								m(
									'form.inline-block.align-bottom.bg-white.rounded-sm.text-left.overflow-visible.shadow-xl.transform.transition-all.sm:my-8.sm:align-middle.sm:max-w-lg.sm:w-full',
									{
										// onclick (e) { e.stopPropagation() },
										onsubmit(e) {
											e.preventDefault()
											if (useAction) {
												action && action()
												useAction = false
											} else {
												save && save()
											}
										},
										class: classes.join(' '),
									},
									[
										m(
											'div.hidden.sm:block.absolute.top-0.right-0.pt-4.pr-4',
											m(
												'button.bg-white.rounded-md.text-gray-400.hover:text-gray-500.focus:outline-none.focus:ring-2.focus:ring-offset-2.focus:ring-indigo-500',
												{
													type: 'button',
													onclick() {
														closeDialog()
													},
												},
												[m('span.sr-only', 'Close'), m(Svg, { classes: ['h-6', 'w-6'] }, xIcon)]
											)
										),
										m('.bg-white.px-4.pt-3.pb-4.sm:p-6.sm:pb-4', [
											m('.grid.gap-3', [m('.text-xl.leading-6.font-medium.text-gray-900', heading), children]),
										]),
										m(
											'.bg-gray-50.px-4.py-3.sm:px-6.flex.flex-col.sm:flex-row.sm:flex-row-reverse.sm:justify-between.sm:items-end.gap-6',
											[
												m('.sm:flex.sm:flex-row-reverse.gap-3', [
													save &&
														m(
															Button,
															{
																classes: ['w-full', 'sm:w-auto'],
																submit: true,
																variant: 'positive',
																loading: saving,
															},
															saveLabel || t('app', 'save')
														),
													action &&
														m(
															Button,
															{
																classes: ['w-full', 'sm:w-auto'],
																submit: true,
																variant: 'positive',
																loading: saving,
																onclick(e) {
																	useAction = true
																	// e.preventDefault()
																	// action()
																},
															},
															actionLabel || 'Action'
														),
													m(
														Button,
														{
															classes: ['w-full', 'sm:w-auto'],
															variant: 'plain',
															type: 'button',
															onclick() {
																closeDialog()
															},
														},
														cancelLabel || t('app', 'cancel')
													),
												]),
												remove &&
													!removeHide &&
													m(
														Button,
														{
															variant: 'delete',
															onclick() {
																if (removeConfirm) {
																	remove()
																} else {
																	removeConfirm = true
																	setTimeout(() => {
																		removeConfirm = false
																		m.redraw()
																	}, 3000)
																}
															},
														},
														removeConfirm ? 'Click again to confirm remove' : removeText || t('app', 'remove')
													),
											]
										),
									]
								),
							]),
						]
					),
				]
			)
		},
	}
}
