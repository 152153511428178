const stringToHslColor = (str, s, l) => {
	let hash = 0
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}

	const h = hash % 360
	return `hsl(${h}, ${s}%, ${l}%)`
}

export const UserAvatar = ({ attrs: { user } }) => {
	const userColour = stringToHslColor(user.email, 50, 70)
	const initials =
		user.firstName && user.lastName ? user.firstName.charAt(0) + user.lastName.charAt(0) : user.email.charAt(0)
	return {
		view({ attrs: { classes = [] } }) {
			return m(
				'.rounded-full.h-8.w-8.bg-orange-400.text-white.flex.justify-center.items-center.flex-shrink-0',
				{
					style: {
						background: userColour,
					},
					class: classes,
				},
				[
					user.avatar
						? m('img.w-8.h-8.rounded-full', {
								src: user.avatar + '?faceindex=1&facepad=2.5&fit=crop&w=100&h=100',
								alt: initials,
						  })
						: m('span.text-sm.font-bold.tracking-wide.leading-none', initials.toUpperCase()),
				]
			)
		},
	}
}

export const Avatar = ({ attrs: { name } }) => {
	const colour = stringToHslColor(name, 50, 70)
	const initial = name.charAt(0)
	return {
		view({ attrs: { classes = [] } }) {
			return m(
				'.rounded-full.h-12.w-12.bg-orange-400.text-white.flex.justify-center.items-center.flex-shrink-0',
				{
					style: {
						background: colour,
					},
					class: classes,
				},
				[m('span.text-sm.font-bold.tracking-wide.leading-none', initial.toUpperCase())]
			)
		},
	}
}
