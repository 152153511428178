import { Radio } from 'components/Radio'

export const RadioGroup = () => {
	return {
		view({ attrs: { label, value, options, name, required, errorMessage, onchange } }) {
			return m('.fieldset', [
				label &&
					m('legend.flex.justify-between', [
						m(`label.block.text-sm.font-bold.text-gray-700`, label),
						required && m('span.text-xs.text-gray-500.mr-1', { title: 'Required' }, '*'),
					]),
				m('.mt-2.space-y-2', [
					options.map((o) => {
						return m(Radio, {
							label: o.label,
							value: o.value,
							desc: o.desc,
							name,
							checked: o.value === value,
							required,
							onchange,
						})
					}),
				]),
				m('.text-red-600.text-sm.ml-10', errorMessage),
			])
		},
	}
}
