import { Button, Card, Breadcrumb, Input, Select } from 'components'
import { getProfile } from 'api/profile'
import { updateProfile } from 'store/profile'
import { notification } from 'utils/notifications'

export const Settings = () => {
	let email
	let profile
	let profileLoading = false
	getProfile().then((p) => {
		if (p) {
			email = p.email
			profile = {
				firstName: p.firstName,
				lastName: p.lastName,
				phoneNumber: p.phoneNumber,
				company: p.company,
				role: p.role,
				occupation: p.occupation,
				townCity: p.townCity,
				country: p.country,
				experience: p.experience,
				experienceDetail: p.experienceDetail,
				imdbLinkedIn: p.imdbLinkedIn,
				avatar: p.avatar,
				emailForBifaInfo: p.emailForBifaInfo,
				emailForVotingInfo: p.emailForVotingInfo,
				distributorsPostalAddress: p.distributorsPostalAddress,
			}
		}
	})

	return {
		view() {
			return [
				m(Breadcrumb, {
					links: [
						{
							title: t('settings', 'title'),
						},
					],
				}),
				profile &&
					m(
						'.p-4.overflow-scroll',
						m('.md:grid.md:grid-cols-3.md:gap-6', [
							m(
								'.md:col-span-1',
								m('.px-4.sm:px-0', [
									m('h3.text-lg.font-medium.leading-6.text-gray-900', t('settings', 'profileTitle')),
									m('p.mt-1.text-sm.text-gray-600'),
								])
							),
							m('dv.mt-5.md:mt-0.md:col-span-2', [
								m(
									'form',
									{
										onsubmit(e) {
											e.preventDefault()
											profileLoading = true
											updateProfile(profile).then(() => {
												notification.success({ title: t('settings', 'profileSaved') })
												profileLoading = false
											})
										},
									},
									[
										m(
											Card,
											{
												buttons: [
													m(
														Button,
														{
															type: 'submit',
															loading: profileLoading,
														},
														t('app', 'save')
													),
												],
											},
											m('.space-y-6.divide-y.divide-gray-200', [
												m('.grid.grid-cols-6.gap-6', [
													m('.col-span-6.md:col-span-4', [
														m(Input, {
															label: 'Username',
															value: email,
															type: 'readonly',
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: t('settings', 'profileFirstName'),
															value: profile.firstName,
															type: 'text',
															required: true,
															oninput(val) {
																profile.firstName = val
															},
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: t('settings', 'profileLastName'),
															value: profile.lastName,
															type: 'text',
															required: true,
															oninput(val) {
																profile.lastName = val
															},
														}),
													]),
													m('.col-span-6', [
														m(Input, {
															label: 'Mobile*',
															type: 'tel',
															value: profile.phoneNumber,
															classes: 'max-w-xs',
															oninput(val) {
																profile.phoneNumber = val
															},
														}),
														m(
															'p.text-xs.text-gray-600',
															'We may also require a mobile number for you to use for certain secure screener login processes for some distributors. If you are happy to share this with us and the distributors, please enter your mobile number here*'
														),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: 'Town/City',
															value: profile.townCity,
															type: 'text',
															oninput(val) {
																profile.townCity = val
															},
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: 'Country',
															value: profile.country,
															type: 'text',
															oninput(val) {
																profile.country = val
															},
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: 'Email for BIFA correspondence',
															value: profile.emailForBifaInfo,
															type: 'email',
															oninput(val) {
																profile.emailForBifaInfo = val
															},
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: 'Email for distributor correspondence',
															value: profile.emailForVotingInfo,
															type: 'email',
															oninput(val) {
																profile.emailForVotingInfo = val
															},
														}),
													]),
													m('.col-span-6.md:col-span-3', [
														m(Input, {
															label: 'IMDB or Linkedin profile link',
															value: profile.imdbLinkedIn,
															oninput(val) {
																profile.imdbLinkedIn = val
															},
														}),
													]),

													m('.col-span-6.md:col-span-3', []),
													m('.col-span-6', [
														m(Input, {
															label: 'Company',
															value: profile.company,
															type: 'text',
															required: false,
															oninput(val) {
																profile.company = val
															},
														}),
													]),
													m('.col-span-6', [
														m(Input, {
															label: 'Please tell us your current occupation (eg Director, Producer, Casting Director)',
															value: profile.occupation,
															type: 'text',
															required: true,
															oninput(val) {
																profile.occupation = val
															},
														}),
													]),
													m('.col-span-6', [
														m(Select, {
															label: 'Is your experience mainly in',
															search: false,
															required: true,
															value: profile.experience,
															onchange(e) {
																profile.experience = e
															},
															strValue: true,
															choices: [
																{ label: 'Select one...', value: '' },
																{ label: 'Fiction Film', value: 'Fiction Film' },
																{
																	label: 'Documentary Film',
																	value: 'Documentary Film',
																},
																{
																	label: 'Equally both Fiction and Documentary film',
																	value: 'Equally both Fiction and Documentary film',
																},
																{
																	label: 'Not applicable (I don’t work in production or distribution)',
																	value: 'Not applicable (I don’t work in production or distribution)',
																},
															],
														}),
													]),
													m('.col-span-6', [
														m(Input, {
															label: 'Experience details',
															value: profile.experienceDetail,
															type: 'text',
															oninput(val) {
																profile.experienceDetail = val
															},
														}),
													]),
												]),
											])
										),
									]
								),
							]),
						])
					),
			]
		},
	}
}
