import { Card, Svg, Heading, Input, Button, Link, Select } from 'components'

import logo from 'assets/logo.svg'

const formData = {}

export const RegisterSuccess = () => {
	return {
		view() {
			return m('.h-full.flex.flex-col.justify-center.items-center.bg-black.h-screen', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-xl', 'mt-3'],
					},
					[
						m('.p-6', [
							m(Heading, t('register', 'title')),
							m('div', [
								m('div', [m('p', 'Thank you for registering. Please click below to login.')]),
								m('.flex.justify-between.items-center.mt-3', [
									m(
										Button,
										{
											href: '/login',
										},
										t('register', 'logInLink')
									),
								]),
							]),
						]),
					]
				),
				m('p.text-center.text-xs.text-gray-400', `App: v${process.env.APP_VERSION}`),
			])
		},
	}
}
