import { Card, Svg, Heading, Input, Button, Link, Select } from 'components'
import { register } from 'api/auth'
import { schema } from 'validators/register'
import { validate, getError, displayErrors } from 'validators/utils'

import logo from 'assets/logo.svg'

const formData = {}

export const Register = () => {
	let errors = []
	let serverErrors
	let loading
	const registerUser = (data) => {
		errors = []
		serverErrors = []
		loading = true
		register(data)
			.then((res) => {
				loading = false
				m.route.set('/register-success')
			})
			.catch((err) => {
				loading = false
			})
	}

	return {
		view() {
			return m('.h-full.flex.flex-col.justify-center.items-center.bg-black.h-screen', [
				m(
					Svg,
					{
						classes: ['w-24'],
					},
					logo
				),
				m(
					Card,
					{
						classes: ['w-4/5', 'max-w-xl', 'mt-3'],
					},
					[
						m('.p-6', [
							m(Heading, t('register', 'title')),
							m(
								'form',
								{
									onsubmit(e) {
										e.preventDefault()
										registerUser(formData)
									},
								},
								[
									m('div.grid.gap-4.grid-cols-2', [
										m(Input, {
											label: 'First Name',
											value: formData.firstName,
											type: 'text',
											required: true,
											oninput(val) {
												formData.firstName = val
											},
										}),
										m(Input, {
											label: 'Last Name',
											value: formData.lastName,
											type: 'text',
											required: true,
											oninput(val) {
												formData.lastName = val
											},
										}),

										m('.col-span-2', [
											m(Input, {
												label: 'Company',
												value: formData.company,
												type: 'text',
												required: false,
												oninput(val) {
													formData.company = val
												},
											}),
										]),
										m('.col-span-2', [
											m(Select, {
												label: 'Role',
												search: false,
												required: true,
												value: formData.role,
												onchange(e) {
													formData.role = e
												},
												strValue: true,
												choices: [
													{ label: 'Select one...', value: '' },
													{ label: 'Publicist', value: 'Publicist' },
													{ label: 'Distributor', value: 'Distributor' },
													{ label: 'Production Company', value: 'Production Company' },
													{ label: 'Filmmaker', value: 'Filmmaker' },
													{ label: 'Sales Agent', value: 'Sales Agent' },
												],
											}),
										]),
										m('.col-span-2', [
											m(Input, {
												label: t('register', 'email'),
												value: formData.email,
												type: 'email',
												required: true,
												errorMessage: getError(errors, 'email'),
												oninput(val) {
													formData.email = val
												},
											}),
										]),
										m('.col-span-2', [
											m(Input, {
												label: 'Mobile',
												value: formData.phoneNumber,
												type: 'text',
												required: true,
												oninput(val) {
													formData.phoneNumber = val
												},
											}),
										]),
										m('.col-span-2', [
											m(Input, {
												label: t('register', 'password'),
												value: formData.newPassword,
												type: 'password',
												required: true,
												pattern: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
												errorMessage: getError(errors, 'password'),
												oninput(val) {
													formData.newPassword = val
												},
											}),
											m(
												'p.text-xs',
												'Passwords must be 8 characters minimum, including at least 1 uppercase, lowercase and digit'
											),
										]),
									]),
									displayErrors(serverErrors),
									m('.flex.justify-between.items-center.mt-3', [
										m(
											Link,
											{
												classes: ['text-orange-900', 'underline'],
												href: '/login',
												options: { replace: true },
											},
											t('register', 'logInLink')
										),
										m(
											Button,
											{
												variant: 'positive',
												loading,
											},
											t('register', 'action')
										),
									]),
								]
							),
						]),
					]
				),
				m('p.text-center.text-xs.text-gray-400', `App: v${process.env.APP_VERSION}`),
			])
		},
	}
}
