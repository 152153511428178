import Joi from 'joi'

export const schema = Joi.object().keys({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Must be a valid email address',
      'string.empty': 'Email address is required',
      'any.required': 'Email address is required'
    }),
  password: Joi.string()
    .min(8)
    .required()
    .messages({
      'string.min': 'Must be over {#limit} characters',
      'string.empty': 'Password is required',
      'any.required': 'Password is required'
    })
})
